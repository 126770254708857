@import 'styles/base/mixins';

.library {
  position: relative;

  &-modal {
    &-content {
      max-height: calc(100vh - 400px);
      margin-top: 1.5rem !important;
      @include scrollbar();
    }

    .modal__title {
      text-transform: capitalize;
    }
  }

  &-edit-icon {
    display: inline-flex !important;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    font-size: $font-size-6;
    width: $font-size-6;
    height: $font-size-6;

    &--xs {
      font-size: $font-size-4;
      width: $font-size-4 !important;
      height: $font-size-4 !important;
    }
  }

  &-sub-table {
    th:not(:first-child),
    td:not(:first-child) {
      text-align: center;
    }
  }

  &-divider {
    &--vertical {
      margin-left: 1rem !important;
      margin-right: 0.5rem !important;
    }
  }

  &-item-name {
    &-panel {
      height: 52px;
    }

    display: flex;
    align-items: center;
    font-family: $product-ui-text-l;

    mat-icon {
      display: flex;
      align-items: center;
    }

    .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
      padding-top: 0px;
      min-height: 32px;
    }
  }

  &-actions-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    cursor: pointer;
    background-color: $primary-orange-50;
    width: 32px;
    height: 32px;
    margin-left: 8px;

    mat-icon {
      color: $primary-orange-600;
    }

    &.cursor-default {
      cursor: default;
    }
  }

  &-option:first-child {
    border-bottom: 1px solid $secondary-neutral-black-300;
  }

  &-option-name {
    font: $product-ui-text-l;
  }

  .mat-divider-vertical {
    margin: 0 1rem;
  }

  .archive-btn {
    top: -60px;
    position: relative;
  }

  .archive-container {
    padding: 1.5rem 0;
    background-color: $secondary-neutral-black-200;

    .mat-mdc-table,
    .mdc-data-table__row {
      background: $secondary-neutral-black-200;
    }
  }

  .tabs-list-container {
    width: 100%;
    max-width: 1440px;
    padding: 0 40px;
    margin-left: auto;
    margin-right: auto;
  }

  &-action-buttons button {
    font-size: $font-size-2 !important;
  }

  .main-action-button {
    height: 32px !important;
    padding: 0 16px;
    margin-right: 1px;
    align-items: center;
    justify-content: center;
    background: $primary-orange-p-500;
    border-radius: 200px;
    color: white;

    &[aria-expanded='true'] {
      background: $primary-orange-600;
    }

    &:active {
      background: $primary-orange-700;

      .mat-mdc-button-persistent-ripple::before {
        opacity: 0;
      }
    }

    &:disabled,
    &.disabled {
      cursor: default;
      background: $primary-blue-violet-100;
      color: $primary-blue-violet-600;

      .mat-mdc-button-persistent-ripple::before {
        opacity: 0;
      }

      .mdc-button__label {
        color: $primary-blue-violet-600;
      }
    }

    & span.mdc-button__label {
      color: $secondary-neutral-black-white;
      font-variant-numeric: lining-nums proportional-nums;

      font-family: $font-families-newjunebold;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0;
      padding-top: 1px;
    }
  }

  .detail-field {
    line-height: 1.25rem;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 30px;
    }

    .title {
      color: $primary-blue-violet-700;
      margin-right: 5px;
    }
    .data {
      font-family: NewJuneBold, serif;
    }
  }
}

.complexity {
  &-header {
    display: flex;
    align-items: center;
    height: 40px;
    background: $secondary-purple-magenta-50;
    font-family: $font-families-newjunebold;

    .column {
      &:nth-child(1) {
        text-transform: uppercase;
      }

      span {
        font-size: $font-size-2;
        line-height: 1rem;
        color: $secondary-purple-magenta-800 !important;
        font-family: NewJuneBold !important;
      }
    }
  }

  &-counter {
    width: 100%;

    .input-count {
      width: 100% !important;
    }
  }

  &-merge-btn {
    display: flex;
    align-items: center;
    font: $product-ui-text-m;
    color: $primary-blue-violet-500;
    cursor: pointer;

    &:hover {
      color: $primary-blue-violet-700;
    }

    mat-icon {
      font-size: $font-size-4;
      width: $font-size-4;
      height: $font-size-4;
      margin-right: 0.25rem;
    }
  }

  .column {
    &:last-child {
      padding-right: 0.5rem;
    }

    &:not(:first-child) {
      text-align: center;
    }

    &.level-1 {
      padding-left: 8px;
    }

    &.level-2 {
      padding-left: 48px;
    }

    &.level-3 {
      padding-left: 84px;
    }
  }

  .arrow {
    transition: 0.3s;

    &.active {
      rotate: 180deg;
    }
  }

  .button-row {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 3rem;

    mat-icon {
      font-size: $font-size-6;
      width: $font-size-6;
      height: $font-size-6;
    }
  }

  .department-row {
    cursor: pointer;
    padding: 0.5rem 0;
  }

  .role-row {
    padding: 0.25rem 0;
  }

  .department-row,
  .role-row {
    border-top: 1px solid $secondary-purple-magenta-50;
    display: flex;
    flex-direction: column;

    .row-content .column:nth-child(1) {
      font-size: $font-size-2;
      font-family: $font-families-newjunebold;
      display: flex;
    }

    .row-content .column:not(:first-child):not(:nth-child(1)) {
      text-align: center;
      font-size: $font-size-1;
      line-height: 13px;
      padding-left: 5px;
    }

    .selected {
      min-height: 60px;
      border: 1px solid $secondary-purple-magenta-300;
    }
  }
}
