@import 'styles/base/variables';

.mat-mdc-form-field-focus-overlay,
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
  padding: 0;
}
.date-input.mat-mdc-form-field {
  width: 100%;
  .mat-mdc-form-field-icon-suffix {
    align-self: flex-end;
    mat-icon {
      padding: 4px;
    }
  }
}
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 28px;
  padding-bottom: 7px;
}
.mdc-text-field {
  .mdc-floating-label.mat-mdc-floating-label {
    color: $primary-blue-violet-600;
    font: $product-ui-text-l;
    mat-label {
      position: relative;
      top: 0.5px;
    }
    &.mdc-floating-label--float-above {
      color: $primary-blue-violet-700;
    }
  }
}
.search-field {
  mat-label {
    color: $primary-blue-violet-p-900;
    font-family: $font-families-newjunebold;
  }
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: $primary-blue-violet-800;
  font: $product-ui-text-bold-l;

  &::placeholder {
    color: $primary-blue-violet-700;
    font: $product-ui-text-m;
  }
}
.mdc-text-field.mdc-text-field--disabled {
  .mdc-text-field__input {
    color: $primary-blue-violet-600;
    font: $product-ui-text-bold-l;

    &::placeholder {
      color: $primary-blue-violet-400;
      font: $product-ui-text-m;
    }
  }

  .mat-mdc-select-value-text {
    color: $primary-blue-violet-600;
  }

  .mat-mdc-select-placeholder {
    color: $primary-blue-violet-400;
  }
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:not(.mat-mdc-form-field-textarea-control) {
  line-height: 131%;
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: transparent;
  padding: 0;
}
.mdc-text-field--disabled .mdc-floating-label {
  color: $primary-blue-violet-600;
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom: 1px dashed $primary-blue-violet-400;
}
.icon-field {
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    font: $product-ui-text-label-l;
    min-height: unset;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 17px;
  }
  &:not(.show-errors) .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 7px;
  }
}
.search-field {
  &.mat-mdc-form-field:not(.mat-form-field-hide-placeholder) {
    width: 304px !important;
    mat-label {
      display: none;
    }
    mat-icon {
      color: $primary-blue-violet-p-900;
    }
    .cancel-icon {
      display: inline-block !important;
      cursor: pointer;
      font-size: 16px;
      text-align: center;
      padding-top: 10px;
    }
  }
  .cancel-icon {
    padding: 2px;
    display: none;
  }
  &.mat-mdc-form-field {
    width: 84px;
    transition: width 0.5s;
    &.search-field-lg {
      width: 124px;
    }
    mat-icon {
      color: $primary-orange-p-500;
    }
  }
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    font: $product-ui-text-label-l;
    min-height: unset;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 17px;
  }
  &:not(.show-errors) .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border: none;
  }
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 7px;
  }
  mat-label {
    color: $primary-blue-violet-p-900;
    font-family: $font-families-newjunebold;
  }

  &--required::after {
    content: '*';
    margin-left: 1px;
    margin-right: 0px;
    color: $primary-orange-p-500;
  }
}
.scope-input--full-sized {
  width: 100% !important;
}

.scope-ui-toggle {
  .mdc-form-field {
    display: flex;
    align-items: center;
    vertical-align: middle;
    flex-direction: row-reverse;
    width: 200px;
  }
}

.mdc-label {
  color: $primary-blue-violet-p-900;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: $font-families-newjunebold;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.label-selected .mdc-form-field .mdc-label {
  color: $primary-blue-violet-p-900;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: $font-families-newjuneregular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-track-color: #{$primary-orange-200};
  --mdc-switch-selected-hover-track-color: #{$primary-orange-200};
  --mdc-switch-selected-handle-color: #{$primary-orange-p-500};
  --mdc-switch-selected-focus-handle-color: #{$primary-orange-p-500};
  --mdc-switch-selected-hover-handle-color: #{$primary-orange-p-500};
  --mdc-switch-selected-pressed-handle-color: #{$primary-orange-p-500};
  --mdc-switch-unselected-track-color: #{$secondary-neutral-black-400};
  --mdc-switch-unselected-hover-track-color: #{$secondary-neutral-black-400};
  --mdc-switch-unselected-handle-color: #{$secondary-neutral-black-100};
  --mdc-switch-unselected-focus-handle-color: #{$secondary-neutral-black-100};
  --mdc-switch-unselected-hover-handle-color: #{$secondary-neutral-black-100};
  --mdc-switch-unselected-pressed-handle-color: #{$secondary-neutral-black-100};
  --mdc-switch-selected-focus-state-layer-color: #{$primary-orange-200};
  --mdc-switch-selected-hover-state-layer-color: #{$primary-orange-200};
  --mdc-switch-selected-pressed-state-layer-color: #{$primary-orange-200};
  --mdc-switch-selected-focus-track-color: #{$primary-orange-200};
  --mdc-switch-selected-pressed-track-color: #{$primary-orange-200};
  --mdc-switch-unselected-hover-state-layer-color: transparent;
  --mdc-switch-track-width: 20px;
  --mdc-switch-track-height: 8px;
  --mdc-switch-handle-width: 12px;
  --mdc-switch-handle-height: 12px;
}

.mat-mdc-slide-toggle .mdc-form-field {
  .mdc-switch__shadow,
  mdc-switch__icons {
    color: $secondary-neutral-black-100;
  }
}

.mdc-switch__icons {
  border: none;
  border-radius: 200px;
}

.icon-selected .mdc-switch__icons {
  border: none;
  border-radius: 200px;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon,
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icons {
  display: none;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  display: none;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
  border-radius: 10px !important;
}
